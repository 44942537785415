import './list.scss';
import dataApi from '@/api/manage/rebateRule.js';
import dataRankApi from '@/api/manage/memberRank.js';
import dataProductApi from '@/api/manage/product.js';

export default {
  name: 'rebte_rule-list',
  components: {},
  data () {
    return {
      dataApi: dataApi,
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      productList: [],
      dialogVisible: false,
      memberBank: [],
      formDa: {},
      formOp: {
        column: [
          {
            label: '关联产品',
            prop: 'product_id',
            span: 24,
            formslot: true,
            rules: [{
              required: true,
              message: '请选择贷款产品',
              trigger: 'blur'
            }]
          },
          {
            type: 'input',
            label: '规则名称',
            span: 24,
            display: true,
            prop: 'title',
            rules: [
              {
                required: true,
                message: '名称必须填写'
              }
            ],
            required: true
          },
          {
            label: '佣金规则',
            type: 'dynamic',
            span: 24,
            prop: 'rule',
            hide: true,
            dataType: 'array',
            children: {
              align: 'center',
              headerAlign: 'center',
              index: false,
              addBtn: false,
              delBtn: false,
              bind: 'prop',
              column: [
                {
                  type: 'input',
                  display: false,
                  prop: 'id',
                  hide: true
                },
                {
                  type: 'title',
                  display: true,
                  styles: {
                    fontSize: '14px',
                    color: '#606266',
                    fontWeight: '400'
                  },
                  label: '会员级别',
                  prop: 'title',
                  width: 120
                },
                {
                  type: 'input',
                  display: true,
                  label: '佣金比例',
                  prop: 'prop',
                  maxlength: 6,
                  append: '%',
                  placeholder: '请设置佣金比例'
                }
              ]
            }
          },
          {
            type: 'textarea',
            label: '规则说明',
            span: 24,
            display: true,
            prop: 'remark'
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: 0
              },
              {
                label: '启动',
                value: 1
              }
            ],
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'status',
            required: true,
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ]
          }
        ],
        clearExclude: ['status', 'rule'],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 100,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center'
      },
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        addBtn: false,
        editBtn: false,
        selection: false,
        delBtn: false,
        column: [
          {
            label: '关联产品',
            prop: 'product_id',
            type: 'table',
            search: true,
            dialogWidth: '60%',
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'title',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.title) return '';
              return `${row.title}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              console.log(page, value, data);
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataProductApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'name',
              value: 'id'
            },
            rules: [
              {
                required: true,
                message: '请选择供应商',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '名称',
            prop: 'title',
            type: 'input',
            search: true
          },
          {
            label: '规则',
            prop: 'rule',
            hide: true,
            type: 'textarea'
          },
          {
            label: '规则说明',
            prop: 'remark',
            type: 'textarea'
          },
          {
            label: '状态',
            span: 12,
            type: 'radio',
            value: 0,
            search: true,
            dicData: [
              {
                label: '启用',
                value: 1
              },
              {
                label: '停用',
                value: 0
              }
            ],
            prop: 'status'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      searchData: {}
    };
  },
  computed: {},
  mounted () {},
  filters: {},
  methods: {
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
      this.getMemberRankList();
    },
    // 筛选
    searchChange (params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    addDialogForm (row) {
      if (row.id !== undefined) {
        this.getInfo(row);
      }
      this.dialogVisible = true;
      this.formDa.rule = this.memberRank;
      // this.$refs.form.clearValidate();
    },
    handleClose () {
      this.$refs.addForm.resetForm();
      this.productList = [];
      this.dialogVisible = false;
    },
    handleSubmit (form, done) {
      if (form.id) {
        dataApi.update.r(form).then(() => {
          this.getList();
          this.dialogVisible = false;
          done(form);
        });
      } else {
        dataApi.add.r(form).then(() => {
          this.getList();
          this.dialogVisible = false;
          done(form);
        });
      }
      this.$refs.addForm.resetForm();
      // this.$refs.form.resetFields();
      // this.$refs.form.clearValidate();
      done();
    },
    // 删除
    rowDel (form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataApi.del.r({ id: form.id }).then(() => {
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    getInfo (row) {
      dataApi.getOne.r({ id: row.id }).then(res => {
        this.formDa = res.data;
        // 设置product回显
        if (res.data.product) {
          this.productList.push(res.data.product);
        }
      });
    },
    // 获取
    getList () {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    // 获取会员级别
    getMemberRankList () {
      const postData = {
        page: 1,
        pageSize: 100
      };
      this.memberRank = [];
      dataRankApi.getList.r(postData).then(res => {
        for (let i = 0; i < res.data.list.length; i++) {
          const row = res.data.list[i];
          this.memberRank.push({
            title: row.name,
            id: row.id
          });
        }
      });
    },
    getProduct (query) {
      console.log('query', query);
      if (query !== '') {
        const postData = {
          title: query
        };
        dataProductApi.getList.r(postData).then(res => {
          this.productList = res.data.list;
        });
      }
    },
    productChangeSelect () {

    }
  }
};
