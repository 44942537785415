<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template slot="menuLeft">
        <el-button icon="el-icon-plus" type="primary" size="small" @click="addDialogForm">添加规则</el-button>
      </template>
      <template slot-scope="{type,size,row,index}" slot="menu">
        <el-button icon="el-icon-edit-outline" :size="size" :type="type" @click.stop="addDialogForm(row,index)">编辑</el-button>
        <el-button icon="el-icon-delete" :size="size" :type="type" @click.stop="rowDel(row,index)">删除</el-button>
      </template>
    </avue-crud>
    <el-dialog
      title="添加/编辑佣金规则"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false" 
      :before-close="handleClose"
      width="60%">
      <avue-form
        ref="addForm"
        :option="formOp"
        v-model="formDa"
        @submit="handleSubmit">
        <template slot="product_id">
          <el-select
            v-model="formDa.product_id"
            filterable
            remote
            placeholder="请输入关键词"
            @visible-change="productChangeSelect"
            :remote-method="getProduct">
            <el-option
              v-for="item in productList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
